<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="pepDataForm">
      <b-form
        @submit.prevent="validationForm"
      >
        <b-card>
          <b-row
            class="justify-content-center"
          >
            <b-col
              sm="11"
            >
              <!-- is PEP -->
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label-cols-sm="12"
                content-cols-sm="12"
                label="¿Ejerce o ha ejercido en los últimos 5 años un cargo público (PEP) o ha sido colaborador directo de la máxima autoridad de la institución?"
                label-for="isPEP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Validar si eres PEP"
                  vid="isPEP"
                >
                  <b-form-radio
                    id="isPEP1"
                    v-model="isPEP"
                    name="isPEP"
                    :aria-describedby="ariaDescribedby"
                    value="yes"
                    class="my-1"
                    :disabled="!canEdit"
                  >
                    Sí
                  </b-form-radio>
                  <b-form-radio
                    id="isPEP2"
                    v-model="isPEP"
                    name="isPEP"
                    :aria-describedby="ariaDescribedby"
                    value="no"
                    class="my-1"
                    :disabled="!canEdit"
                  >
                    No
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-show="isPEP=='yes'"
            class="justify-content-center"
          >
            <b-col
              sm="11"
            >
              <!-- Basic data  section -->
              <!-- <h4 class="my-2">
                Datos del socio PEP persona natural o del PEP relacionado con socio persona jurídica
              </h4> -->
              <div
                style="text-align: center"
              >
                <h4 class="mb-2 mt-4">
                  DATOS DEL CLIENTE PEP PERSONA NATURAL O DEL PEP RELACIONADO CON EL CLIENTE PERSONA JURÍDICA
                </h4>
              </div>
              <!-- paternal lastname -->
              <b-form-group
                label="Apellido paterno"
                label-for="plastname"
              >
                <b-form-input
                  id="plastname"
                  v-model="plastname"
                  name="plastname"
                  disabled
                  :readonly="!canEdit"
                />
              </b-form-group>
              <!-- maternal lastname -->
              <b-form-group
                label="Apellido materno"
                label-for="mlastname"
              >
                <b-form-input
                  id="mlastname"
                  v-model="mlastname"
                  name="mlastname"
                  disabled
                  :readonly="!canEdit"
                />
              </b-form-group>
              <!-- names -->
              <b-form-group
                label="Nombres"
                label-for="names"
              >
                <b-form-input
                  id="names"
                  v-model="names"
                  name="names"
                  disabled
                  :readonly="!canEdit"
                />
              </b-form-group>
              <!-- PEP section -->
              <!-- <h4 class="mt-4 mb-2">
                Datos del socio persona jurídica relacionada
              </h4> -->
              <b-col
                sm="12"
                style="text-align: center"
              >
                <h4 class="mb-2 mt-4">
                  DATOS DE LA PERSONA JURÍDICA RELACIONADA
                </h4>
              </b-col>
              <!-- Bussiness name section -->
              <b-form-group
                label="Nombre de la institución, organismo público u organización internacional"
                label-for="businessName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre de la institución, organismo público u organización internacional"
                  rules="max:100"
                  vid="businessName"
                >
                  <b-form-input
                    id="businessName"
                    v-model="businessName"
                    :state="errors.length > 0 ? false:null"
                    name="businessName"
                    :readonly="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- document number -->
              <b-form-group
                label="R.U.C."
                label-for="ruc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="R.U.C."
                  rules="min:11"
                  vid="ruc"
                >
                  <b-form-input
                    id="ruc"
                    v-model="ruc"
                    maxlength="11"
                    :state="errors.length > 0 ? false:null"
                    name="ruc"
                    :formatter="formatterNumber"
                    :readonly="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- position -->
              <b-form-group
                label="Cargo que desempeña o ha desempeñado"
                label-for="position"
              >
                <b-form-select
                  id="position"
                  v-model="position"
                  :options="optionsPublicOffices"
                  value-field="varCodigo"
                  text-field="cargoPublico"
                  name="position"
                  :disabled="!canEdit"
                />
              </b-form-group>
              <!-- start date position -->
              <b-form-group
                label="Desde"
                label-for="startDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fecha de inicio"
                  vid="startDate"
                >
                  <!-- <b-form-datepicker
                    id="startDate"
                    v-model="startDate"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="es"
                    :max="endDate"
                    name="startDate"
                    :state="errors.length > 0 ? false:null"
                    required
                  />
                  <b-form-input
                    v-show="false"
                    v-model="startDate"
                    :state="errors.length > 0 ? false:null"
                  /> -->
                  <flat-pickr
                    id="startDate"
                    v-model="startDate"
                    placeholder="dd/mm/aaaa"
                    name="startDate"
                    :state="errors.length > 0 ? false:null"
                    :config="{ ...configDatePickr, maxDate: endDate }"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row class="justify-content-center mt-3 mb-2">
                <b-form-group
                  label="¿Sigue desempeñando el cargo?"
                  label-for="stillPosition"
                >
                  <b-form-radio-group
                    id="stillPosition"
                    v-model="stillPosition"
                    class="text-center mt-1"
                    :options="optionsPublic"
                    value-field="value"
                    text-field="text"
                    name="stillPosition"
                    :disabled="!canEdit"
                  />
                </b-form-group>
              </b-row>
              <!-- end date position -->
              <b-form-group
                v-if="stillPosition === 'no'"
                label="Hasta"
                label-for="endDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fecha de fin"
                  vid="endDate"
                >
                  <!-- <b-form-datepicker
                    id="endDate"
                    v-model="endDate"
                    placeholder=""
                    :min="startDate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="es"
                    :max="today"
                    name="endDate"
                    :state="errors.length > 0 ? false:null"
                    required
                  />
                  <b-form-input
                    v-show="false"
                    v-model="endDate"
                    :state="errors.length > 0 ? false:null"
                  /> -->
                  <flat-pickr
                    id="endDate"
                    v-model="endDate"
                    placeholder="dd/mm/aaaa"
                    name="endDate"
                    :state="errors.length > 0 ? false:null"
                    :config="{ ...configDatePickr, maxDate: today }"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- institution -->
              <!-- <b-form-group
                label="Nombre de la institución, organismo público u organización internacional"
                label-for="institution"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre de la institución, organismo público u organización internacional"
                  rules="max:50"
                  vid="institution"
                >
                  <b-form-input
                    id="institution"
                    v-model="institution"
                    :state="errors.length > 0 ? false:null"
                    name="institution"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- <h4 class="mb-2 mt-4">
                Datos de los familiares del PEP
              </h4> -->
              <b-col
                sm="12"
                style="text-align: center"
              >
                <h4 class="mb-2 mt-4">
                  DATOS DE LOS FAMILIARES DEL PEP
                </h4>
              </b-col>
              <h5 class="my-2">
                De ser PEP, indique los datos de sus parientes vivos hasta el 2do. grado de consanguinidad y 2do. grado de afinidad. Información solicitada de acuerdo a la Resolución SBS N°5060-2018
              </h5>
              <div
                v-for="( familiarPEP, index ) in familiarsPEP"
                :key="familiarPEP+index"
                :familiar="familiarPEP+index"
                class="container-border p-2 my-2"
              >
                <b-row
                  class="justify-content-start"
                >
                  <!-- <b-col
                    sm="12"
                    md="3"
                  >
                    <h5 class="mt-2">
                      Familiar {{ index+1 }}
                    </h5>
                  </b-col> -->
                  <b-col
                    sm="12"
                    style="text-align: center"
                  >
                    <h5 class="mb-3">
                      DATOS DEL FAMILIAR {{ index + 1 }}
                    </h5>
                  </b-col>
                  <!-- delete button -->
                  <!-- <b-col
                    sm="12"
                    md="1"
                  >
                    <b-button
                      class="my-1"
                      variant="danger"
                      block
                      type="button"
                      @click="deleteFamiliar(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col> -->
                </b-row>
                <!-- code PEP -->
                <b-form-group
                  label="Código"
                  :label-for="`${index}familiarPEPcode`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Código"
                    :vid="`${index}familiarPEPcode`"
                  >
                    <b-form-select
                      :id="`${index}familiarPEPcode`"
                      v-model="familiarPEP.familiarPEPcode"
                      :options="codeFamiliarPEP"
                      :state="errors.length > 0 ? false:null"
                      name="familiarCodePEP"
                      :disabled="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- familiar lastname PEP -->
                <b-form-group
                  label="Apellido paterno"
                  :label-for="`${index}familiarPEPplastname`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Apellido Paterno"
                    rules="max:20"
                    :vid="`${index}familiarPEPplastname`"
                  >
                    <b-form-input
                      :id="`${index}familiarPEPplastname`"
                      v-model="familiarPEP.familiarPEPplastname"
                      :state="errors.length > 0 ? false:null"
                      name="familiarPEPplastname"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- maternal lastname PEP-->
                <b-form-group
                  label="Apellido materno"
                  :label-for="`${index}familiarPEPmlastname`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Apellido Materno"
                    rules="max:20"
                    :vid="`${index}familiarPEPmlastname`"
                  >
                    <b-form-input
                      :id="`${index}familiarPEPmlastname`"
                      v-model="familiarPEP.familiarPEPmlastname"
                      :state="errors.length > 0 ? false:null"
                      name="familiarPEPmlastname"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- names PEP-->
                <b-form-group
                  label="Nombres"
                  :label-for="`${index}names`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nombres"
                    rules="max:100"
                    :vid="`${index}names`"
                  >
                    <b-form-input
                      :id="`${index}names`"
                      v-model="familiarPEP.familiarPEPnames"
                      :state="errors.length > 0 ? false:null"
                      name="familiarPEPnames"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- age PEP -->
                <b-form-group
                  label="Edad estimada"
                  :label-for="`${index}familiarPEPage`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Edad estimada"
                    rules="max:2"
                    :vid="`${index}familiarPEPage`"
                  >
                    <b-form-input
                      :id="`${index}familiarPEPage`"
                      v-model.number="familiarPEP.familiarPEPage"
                      :state="errors.length > 0 ? false:null"
                      name="familiarPEPage"
                      maxlength="2"
                      :formatter="formatterNumber"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Buttons -->
                <b-col
                  sm="12"
                >
                  <b-row style="justify-content: flex-end;">
                    <b-col md="6">
                      <b-row>
                        <!-- Add Button -->
                        <b-col class="mb-50">
                          <b-row class="justify-content-center">
                            <b-button
                              variant="primary"
                              class="m-1 mt-md-2"
                              :disabled="!canEdit"
                              @click="addedFamiliar"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-25"
                              />
                              <span class="align-middle">Añadir nuevo</span>
                            </b-button>
                          </b-row>
                        </b-col>
                        <!-- Remove Button -->
                        <b-col
                          v-if="familiarsPEP.length>0"
                          class="mb-50"
                        >
                          <b-row class="justify-content-center">
                            <b-button
                              variant="outline-danger"
                              class="mt-1 mt-md-2"
                              :disabled="!canEdit"
                              @click="deleteFamiliar(index)"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                                class="mr-25"
                              />
                              <span class="align-middle">Eliminar</span>
                            </b-button>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </div>
              <b-row
                v-if="familiarsPEP.length===0"
              >
                <b-col
                  sm="12"
                  md="3"
                >
                  <!-- submit button -->
                  <b-button
                    class="my-1"
                    variant="secondary"
                    block
                    type="button"
                    :disabled="!canEdit"
                    @click="addedFamiliar"
                  >
                    Agregar familiar
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <!-- Spouse Family PEP Section-->
            <b-col
              sm="11"
              style="text-align: center"
            >
              <h4 class="mb-2 mt-3">
                DATOS DEL/DE LA CÓNYUGE O CONVIVIENTE DEL PEP Y SUS FAMILIARES (DEL/DE LA CÓNYUGE)
              </h4>
            </b-col>
            <!-- has Spouse PEP -->
            <b-col
              sm="11"
              class="my-2"
            >
              <b-form-group
                label-cols-sm="12"
                content-cols-sm="12"
                label="¿Es usted casado(a) o tiene conviviente?"
                label-for="hasSpouse"
              >
                <b-form-radio
                  id="hasSpouse1"
                  v-model="hasSpouse"
                  name="hasSpouse"
                  value="yes"
                  class="my-1"
                  :disabled="!canEdit"
                >
                  Sí
                </b-form-radio>
                <b-form-radio
                  id="hasSpouse2"
                  v-model="hasSpouse"
                  name="hasSpouse"
                  value="no"
                  class="my-1"
                  :disabled="!canEdit"
                >
                  No
                </b-form-radio>
              </b-form-group>
            </b-col>
            <!-- Spouse PEP section -->
            <b-col
              v-if="hasSpouse == 'yes'"
              ref="box1"
              sm="11"
            >
              <!-- Spouse Family PEP Data-->
              <b-row
                v-for="( familiarSpousePEP, i ) in familySpousePEP"
                :key="familiarSpousePEP+i+2"
                class="container-border py-2 my-2"
              >
                <!-- header familiar spouse PEP -->
                <b-col
                  sm="12"
                  style="text-align: center"
                >
                  <h4
                    v-if="i >= 1"
                    class="mb-3"
                  >
                    DATOS DEL FAMILIAR N° {{ i }} (DEL/DE LA) CONYUGE
                  </h4>
                  <h4
                    v-else
                    class="mb-3"
                  >
                    DATOS DEL/DE LA CÓNYUGE
                  </h4>
                </b-col>
                <!-- plastname familiar spouse PEP -->
                <b-col md="4">
                  <b-form-group
                    label="Apellido paterno"
                    :label-for="`${i}familiarSpousePEPplastname`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apellido paterno"
                      rules="max:20"
                      :vid="`${i}familiarSpousePEPplastname`"
                    >
                      <b-form-input
                        :id="`${i}familiarSpousePEPplastname`"
                        v-model="familiarSpousePEP.familiarSpousePEPplastname"
                        :state="errors.length > 0 ? false:null"
                        name="familiarSpousePEPplastname"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- mlastname familiar spouse PEP -->
                <b-col md="4">
                  <b-form-group
                    label="Apellido materno"
                    :label-for="`${i}familiarSpousePEPmlastname`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apellido materno"
                      rules="max:20"
                      :vid="`${i}familiarSpousePEPmlastname`"
                    >
                      <b-form-input
                        :id="`${i}familiarSpousePEPmlastname`"
                        v-model="familiarSpousePEP.familiarSpousePEPmlastname"
                        :state="errors.length > 0 ? false:null"
                        name="familiarSpousePEPmlastname"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- names familiar spouse PEP -->
                <b-col md="4">
                  <b-form-group
                    label="Nombres"
                    :label-for="`${i}familiarSpousePEPnames`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombres"
                      rules="max:100"
                      :vid="`${i}familiarSpousePEPnames`"
                    >
                      <b-form-input
                        :id="`${i}familiarSpousePEPnames`"
                        v-model="familiarSpousePEP.familiarSpousePEPnames"
                        :state="errors.length > 0 ? false:null"
                        name="familiarSpousePEPnames"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Born Date familiar spouse PEP -->
                <b-col md="4">
                  <b-form-group
                    label="Fecha de nacimiento"
                    :label-for="`${i}familiarSpousePEPbornDate`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Fecha de Nacimiento"
                      :vid="`${i}familiarSpousePEPbornDate`"
                    >
                      <!-- <b-form-datepicker
                        :id="`${i}familiarSpousePEPbornDate`"
                        v-model="familiarSpousePEP.familiarSpousePEPbornDate"
                        locale="es"
                        placeholder=""
                        :max="isAdult"
                        name="familiarSpousePEPbornDate"
                        :state="errors.length > 0 ? false:null"
                        class="PEP-Container-datepicker"
                      />
                      <b-form-input
                        v-show="false"
                        v-model="familiarSpousePEP.familiarSpousePEPbornDate"
                        :state="errors.length > 0 ? false:null"
                      /> -->
                      <flat-pickr
                        :id="`${i}familiarSpousePEPbornDate`"
                        v-model="familiarSpousePEP.familiarSpousePEPbornDate"
                        placeholder="dd/mm/aaaa"
                        :name="`${i}familiarSpousePEPbornDate`"
                        :state="errors.length > 0 ? false:null"
                        :config="{ ...configDatePickr, maxDate: isAdult, minDate }"
                        :disabled="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Type Document familiar spouse PEP -->
                <b-col md="4">
                  <b-form-group
                    label="Tipo de documento"
                    :label-for="`${i}familiarSpousePEPtypeDocument`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de documento"
                      :vid="`${i}familiarSpousePEPtypeDocument`"
                    >
                      <b-form-select
                        :id="`${i}familiarSpousePEPtypeDocument`"
                        v-model="familiarSpousePEP.familiarSpousePEPtypeDocument"
                        :options="optionsTypeDocument"
                        :state="errors.length > 0 ? false:null"
                        name="familiarSpousePEPtypeDocument"
                        :disabled="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Number Document familiar spouse PEP -->
                <b-col md="4">
                  <b-form-group
                    label="Número de documento"
                    :label-for="`${i}familiarSpousePEPnumberDocument`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Número de Documento"
                      :rules="`document:${familiarSpousePEP.familiarSpousePEPtypeDocument}`"
                      :vid="`${i}familiarSpousePEPnumberDocument`"
                    >
                      <b-form-input
                        :id="`${i}familiarSpousePEPnumberDocument`"
                        v-model="familiarSpousePEP.familiarSpousePEPnumberDocument"
                        :disabled="familiarSpousePEP.familiarSpousePEPtypeDocument ? false:true"
                        :state="errors.length > 0 ? false:null"
                        name="familiarSpousePEPnumberDocument"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- isPEP familiar spouse PEP -->
                <b-col
                  sm="12"
                >
                  <b-row class="justify-content-center">
                    <b-form-group
                      label="¿El familiar es PEP?"
                      :label-for="`${i}familiarSpousePEPisPEP`"
                    >
                      <b-form-radio-group
                        :id="`${i}familiarSpousePEPisPEP`"
                        v-model="familiarSpousePEP.familiarSpousePEPisPEP"
                        class="text-center mt-1"
                        :options="optionsPublic"
                        value-field="value"
                        text-field="text"
                        :name="`${i}familiarSpousePEPisPEP`"
                        :disabled="!canEdit"
                      />
                    </b-form-group>
                  </b-row>
                </b-col>
                <b-col
                  sm="12"
                >
                  <b-row
                    v-if="familiarSpousePEP.familiarSpousePEPisPEP == 'yes'"
                    ref="box1"
                    class="repeater-form justify-content-center"
                  >
                    <!-- country familiar spouse PEP -->
                    <b-col md="12">
                      <b-form-group
                        label="País donde desarrolla o ha desarrollado la  función pública"
                        :label-for="`${i}familiarSpousePEPcountry`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="País donde desarrolla o ha desarrollado la  función pública"
                          :vid="`${i}familiarSpousePEPcountry`"
                        >
                          <b-form-select
                            :id="`${i}familiarSpousePEPcountry`"
                            v-model="familiarSpousePEP.familiarSpousePEPcountry"
                            :options="optionsCountry"
                            text-field="varDescripcion"
                            value-field="varCodigo"
                            :state="errors.length > 0 ? false:null"
                            name="familiarSpousePEPcountry"
                            :disabled="!canEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- institution familiar spouse PEP -->
                    <b-col md="12">
                      <b-form-group
                        label="Nombre de la institución, organismo público u organización internacional"
                        :label-for="`${i}familiarSpousePEPinstitution`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nombre de la institución, organismo público u organización internacional"
                          :vid="`${i}familiarSpousePEPinstitution`"
                        >
                          <b-form-input
                            :id="`${i}familiarSpousePEPinstitution`"
                            v-model="familiarSpousePEP.familiarSpousePEPinstitution"
                            :state="errors.length > 0 ? false:null"
                            name="familiarSpousePEPinstitution"
                            :readonly="!canEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- occupation familiar spouse PEP -->
                    <b-col md="12">
                      <b-form-group
                        label="Cargo que tiene o ha desempeñado"
                        :label-for="`${i}familiarSpousePEPoccupation`"
                      >
                        <b-form-select
                          :id="`${i}familiarSpousePEPoccupation`"
                          v-model="familiarSpousePEP.familiarSpousePEPoccupation"
                          :options="optionsPublicOffices"
                          value-field="varCodigo"
                          text-field="cargoPublico"
                          :name="`${i}familiarSpousePEPoccupation`"
                          :disabled="!canEdit"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Buttons -->
                <b-col
                  sm="12"
                >
                  <b-row style="justify-content: flex-end;">
                    <b-col md="6">
                      <b-row>
                        <!-- Add Button -->
                        <b-col class="mb-50">
                          <b-row class="justify-content-center">
                            <b-button
                              variant="primary"
                              class="m-1 mt-md-2"
                              :disabled="!canEdit"
                              @click="addFamiliarSpousePEP()"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-25"
                              />
                              <span class="align-middle">Añadir nuevo</span>
                            </b-button>
                          </b-row>
                        </b-col>
                        <!-- Remove Button -->
                        <b-col
                          v-if="familySpousePEP.length>1"
                          class="mb-50"
                        >
                          <b-row class="justify-content-center">
                            <b-button
                              variant="outline-danger"
                              class="m-1 mt-md-2"
                              :disabled="!canEdit"
                              @click="removeFamiliarSpousePEP(i)"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                                class="mr-25"
                              />
                              <span class="align-middle">Eliminar</span>
                            </b-button>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- add familiar Spouse PEP-->
              <b-col
                v-if="familySpousePEP.length===0"
                sm="12"
              >
                <b-col
                  sm="10"
                  md="3"
                >
                  <!-- submit button -->
                  <b-button
                    class="my-1"
                    variant="secondary"
                    block
                    type="button"
                    :disabled="!canEdit"
                    @click="addFamiliarSpousePEP()"
                  >
                    Agregar familiar
                  </b-button>
                </b-col>
              </b-col>
            </b-col>
            <!-- Header shares -->
            <b-col
              sm="11"
              style="text-align: center"
            >
              <h4 class="mb-2 mt-3">
                EMPRESAS EN LAS QUE USTED ES ACCIONISTA CON EL 25% O MAS DE PARTICIPACIÓN
              </h4>
            </b-col>
            <b-col
              v-for="( share, i ) in shares"
              :key="share+i+3"
              sm="11"
              class="container-border py-2 my-2"
            >
              <b-row
                class="justify-content-md-center my-1"
              >
                <b-col
                  md="10"
                  style="text-align: center"
                >
                  <h5 class="my-1">
                    DATOS DE LA EMPRESA N° {{ i+1 }}
                  </h5>
                </b-col>
                <!-- Business name section -->
                <b-col md="12">
                  <b-form-group
                    label="Razón social"
                    :label-for="`${i}businessName`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Razón social"
                      rules="max:100"
                      :vid="`${i}businessName`"
                    >
                      <b-form-input
                        :id="`${i}businessName`"
                        v-model="share.businessName"
                        :state="errors.length > 0 ? false:null"
                        name="businessName"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- document number -->
                <b-col md="12">
                  <b-form-group
                    label="R.U.C."
                    :label-for="`${i}businessRuc`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="R.U.C."
                      rules="min:11"
                      :vid="`${i}businessRuc`"
                    >
                      <b-form-input
                        :id="`${i}businessRuc`"
                        v-model="share.businessRuc"
                        maxlength="20"
                        :state="errors.length > 0 ? false:null"
                        name="businessRuc"
                        :formatter="formatterNumber"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Porcentaje de participación"
                    :label-for="`${i}businessPercentage`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Porcentaje de participación"
                      :vid="`${i}businessPercentage`"
                    >
                      <b-form-input
                        :id="`${i}businessPercentage`"
                        v-model="share.businessPercentage"
                        maxlength="4"
                        :state="errors.length > 0 ? false:null"
                        name="businessPercentage"
                        :formatter="formatterDecimal"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Buttons -->
              <b-row style="justify-content: flex-end;">
                <b-col md="6">
                  <b-row>
                    <!-- Add Button -->
                    <b-col class="mb-50">
                      <b-row class="justify-content-center">
                        <b-button
                          variant="primary"
                          class="m-1 mt-md-2"
                          :disabled="!canEdit"
                          @click="addShares()"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span class="align-middle">Añadir nuevo</span>
                        </b-button>
                      </b-row>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      v-if="shares.length>0"
                      class="mb-50"
                    >
                      <b-row class="justify-content-center">
                        <b-button
                          variant="outline-danger"
                          class="m-1 mt-md-2"
                          :disabled="!canEdit"
                          @click="removeShares(i)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-25"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <!-- add shares PEP-->
            <b-col
              v-if="shares.length==0"
              sm="11"
            >
              <b-col md="4">
                <!-- submit button -->
                <b-button
                  class="my-1"
                  variant="secondary"
                  block
                  type="button"
                  :disabled="!canEdit"
                  @click="addShares()"
                >
                  Agregar empresa
                </b-button>
              </b-col>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row
            class="justify-content-center"
          >
            <b-col
              sm="11"
              class="mt-2"
            >
              <!-- is PEP -->
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label-cols-sm="12"
                content-cols-sm="12"
                label="Es familiar de PEP (hasta el segundo grado de consanguinidad o afinidad)"
                label-for="isFamiliarPEP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Validar si eres PEP"
                  vid="isFamiliarPEP"
                >
                  <b-form-radio
                    id="isFamiliarPEP1"
                    v-model="isFamiliarPEP"
                    name="isFamiliarPEP"
                    :aria-describedby="ariaDescribedby"
                    value="yes"
                    class="my-1"
                    :disabled="!canEdit"
                  >
                    Sí
                  </b-form-radio>
                  <b-form-radio
                    id="isFamiliarPEP2"
                    v-model="isFamiliarPEP"
                    name="isFamiliarPEP"
                    :aria-describedby="ariaDescribedby"
                    value="no"
                    class="my-1"
                    :disabled="!canEdit"
                  >
                    No
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-show="isFamiliarPEP==='yes'"
            class="justify-content-center"
          >
            <b-col
              v-for="( famPEP, i ) in isFamilyPEP"
              :key="famPEP+i+3"
              sm="11"
              class="container-border py-2 my-2"
            >
              <div
                style="text-align: center"
              >
                <h5 class="my-2">
                  SI USTED NO ES PEP, PERO FAMILIAR DEL PEP N° {{ i+1 }}
                </h5>
              </div>
              <h5 class="mt-2 mb-3">
                Es Ud. esposo/a o conviviente, padre/madre, hijo/a, hermano/a de alguna persona que desarrolle o haya desarrollado algún cargo político o función pública, en los últimos 5 años? Sies así, por favor señale:
              </h5>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    label="Nombre completo de la persona que desarrolla o ha desarrollado la función pública"
                    :label-for="`${i}isFamiliarPEPname`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre del familiar PEP"
                      rules="max:100"
                      :vid="`${i}isFamiliarPEPname`"
                    >
                      <b-form-input
                        :id="`${i}isFamiliarPEPname`"
                        v-model="famPEP.isFamiliarPEPname"
                        :state="errors.length > 0 ? false:null"
                        name="isFamiliarPEPname"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Qué vínculo familiar tiene Ud. con la persona (Yo soy su ...)"
                    :label-for="`${i}isFamiliarPEPrelationship`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Vínculo familiar"
                      rules="max:20"
                      :vid="`${i}isFamiliarPEPrelationship`"
                    >
                      <b-form-select
                        :id="`${i}isFamiliarPEPrelationship`"
                        v-model="famPEP.isFamiliarPEPrelationship"
                        :options="relationship"
                        :state="errors.length > 0 ? false:null"
                        name="isFamiliarPEPrelationship"
                        :disabled="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="País donde se ha desarrollado la función pública"
                    :label-for="`${i}isFamiliarPEPcountry`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="País del familiar PEP"
                      rules="max:30"
                      :vid="`${i}isFamiliarPEPcountry`"
                    >
                      <b-form-select
                        :id="`${i}isFamiliarPEPcountry`"
                        v-model="famPEP.isFamiliarPEPcountry"
                        :options="optionsCountry"
                        text-field="varDescripcion"
                        value-field="varCodigo"
                        :state="errors.length > 0 ? false:null"
                        name="isFamiliarPEPcountry"
                        :disabled="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Nombre de la institución, organismo público u organización internacional"
                    :label-for="`${i}isFamiliarPEPbussinessName`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre de la institución, organismo público u organización internacional"
                      :vid="`${i}isFamiliarPEPbussinessName`"
                    >
                      <b-form-input
                        :id="`${i}isFamiliarPEPbussinessName`"
                        v-model="famPEP.isFamiliarPEPbussinessName"
                        :state="errors.length > 0 ? false:null"
                        name="isFamiliarPEPbussinessName"
                        :readonly="!canEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Cargo que tiene o ha desempeñado"
                    :label-for="`${i}isFamiliarPEPposition`"
                  >
                    <b-form-select
                      :id="`${i}isFamiliarPEPposition`"
                      v-model="famPEP.isFamiliarPEPposition"
                      :options="optionsPublicOffices"
                      value-field="varCodigo"
                      text-field="cargoPublico"
                      :name="`${i}isFamiliarPEPposition`"
                      :disabled="!canEdit"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row style="justify-content: flex-end;">
                <b-col md="6">
                  <b-row>
                    <!-- Add Button -->
                    <b-col class="mb-50">
                      <b-row class="justify-content-center">
                        <b-button
                          variant="primary"
                          class="m-1 mt-md-2"
                          :disabled="!canEdit"
                          @click="addIsFamiliarPEP()"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span class="align-middle">Añadir nuevo</span>
                        </b-button>
                      </b-row>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      v-if="isFamilyPEP.length>1"
                      class="mb-50"
                    >
                      <b-row class="justify-content-center">
                        <b-button
                          variant="outline-danger"
                          class="m-1 mt-md-2"
                          :disabled="!canEdit"
                          @click="removeIsFamiliarPEP(i)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-25"
                          />
                          <span class="align-middle">Añadir nuevo</span>
                        </b-button>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <!-- add isFamilyPEP-->
            <b-col
              v-if="isFamilyPEP.length==0"
              sm="11"
            >
              <b-col md="4">
                <!-- submit button -->
                <b-button
                  class="my-1"
                  variant="secondary"
                  block
                  type="button"
                  :disabled="!canEdit"
                  @click="addIsFamiliarPEP()"
                >
                  Agregar familiar
                </b-button>
              </b-col>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row
            class="justify-content-center"
          >
            <b-col
              sm="11"
            >
              <validation-provider
                #default="{ errors }"
                name="Declaración jurada"
                vid="affirmedAffidavit"
              >
                <b-form-checkbox
                  id="affirmedAffidavit-1"
                  v-model="affirmedAffidavit"
                  name="affirmedAffidavit-1"
                  value="yes"
                  unchecked-value="no"
                  :state="errors.length > 0 ? false:null"
                  :disabled="!canEdit"
                >
                  Afirmo y ratifico todo lo manifestado en la presente declaración jurada.
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-row
          class="justify-content-center"
        >
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              :to="{name:'Professional-data'}"
              block
            >
              Volver
            </b-button>
          </b-col>
          <b-col
            sm="12"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              {{ canEdit ? "Guardar y avanzar" : "Siguiente" }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormGroup, BFormInput, BFormRadio, BFormSelect, BButton, BRow, BCol, BFormRadioGroup, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import useJWT from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { relationship } from '@helpers/data.js'
import countriesOptions from '@utils/countriesOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import publicOfficesOptions from '@utils/publicOfficesOptions'

/* eslint-disable */
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
/* eslint-enable */

export default {
  components: {
    BCard,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const isAdult = new Date(today)
    isAdult.setFullYear(isAdult.getFullYear() - 18)
    const minDate = new Date(today)
    minDate.setFullYear(minDate.getFullYear() - 100)
    return {
      email: '',
      userRuc: '',
      isPEP: 'no',
      plastname: '',
      mlastname: '',
      names: '',

      businessName: '',
      ruc: '',
      institution: '-',
      position: '',
      stillPosition: 'no',
      startDate: '',
      endDate: '',

      today,

      familiarsPEP: [],
      codeFamiliarPEP: [
        { value: 'A', text: 'A: Padres' },
        { value: 'B', text: 'B: Abuelos' },
        { value: 'C', text: 'C: Hermanoss' },
        { value: 'D', text: 'D: Hijos en común y propios' },
        { value: 'E', text: 'E: Nietos' },
      ],

      optionsCountry: countriesOptions,
      isAdult,
      minDate,
      hasSpouse: '',
      familySpousePEP: [],

      optionsTypeDocument: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: '1', text: 'DNI' },
        { value: '2', text: 'Carnét PNP' },
        { value: '3', text: 'Carnét FFAA' },
        { value: '4', text: 'Carnet Extranjeria' },
        { value: '6', text: 'RUC' },
        { value: '7', text: 'Pasaporte' },
      ],

      optionsPublic: [
        { value: 'yes', text: 'Si' },
        { value: 'no', text: 'No' },
      ],

      optionsPublicOffices: publicOfficesOptions,

      shares: [],

      // Options values
      required,

      isFamiliarPEP: 'no',
      isFamilyPEP: [],

      relationship,

      affirmedAffidavit: 'no',

      // Configs DatePickr
      configDatePickr: {
        allowInput: true,
        altInput: true,
        locale: Spanish,
        altFormat: 'd/m/Y',
      },
      userType: null,
      canEdit: JSON.parse(localStorage.getItem('userData')).canEdit || false,
    }
  },
  watch: {
    shareholderPorcent() {
      const porcent = this.shareholderPorcent
      const regexPorcent = /^[0-9][0-9]?$|^100$/
      if ((!regexPorcent.test(porcent))) this.shareholderPorcent = ''
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.userType = userData.type
    if (userData.type === 0) {
      this.canEdit = true
    }
    this.email = (userData.email) ? userData.email : ''
    this.userRuc = (userData.ruc) ? userData.ruc : ''
    console.log(this.userRuc)
    this.isPEP = (userData.pepData && userData.pepData.isPEP) ? userData.pepData.isPEP : 'no'

    this.plastname = (userData.personalData && userData.personalData.plastname) ? userData.personalData.plastname : ''
    this.mlastname = (userData.personalData && userData.personalData.mlastname) ? userData.personalData.mlastname : ''
    this.names = (userData.personalData && userData.personalData.names) ? userData.personalData.names : ''

    this.businessName = (userData.pepData && userData.pepData.businessName) ? userData.pepData.businessName : ''
    this.ruc = (userData.pepData && userData.pepData.ruc) ? userData.pepData.ruc : ''
    this.institution = (userData.pepData && userData.pepData.institution) ? userData.pepData.institution : '-'
    this.position = (userData.pepData && userData.pepData.position) ? userData.pepData.position : ''
    this.stillPosition = (userData.pepData && userData.pepData.stillPosition) ? userData.pepData.stillPosition : 'no'
    this.startDate = (userData.pepData && userData.pepData.startDate) ? userData.pepData.startDate : ''
    this.endDate = (userData.pepData && userData.pepData.endDate) ? userData.pepData.endDate : ''
    this.familiarsPEP = (userData.pepData && userData.pepData.familiarsPEP) ? userData.pepData.familiarsPEP : []
    this.hasSpouse = (userData.pepData && userData.pepData.hasSpouse) ? userData.pepData.hasSpouse : 'no'
    this.familySpousePEP = (userData.pepData && userData.pepData.familySpousePEP) ? userData.pepData.familySpousePEP : [{
      familiarSpousePEPplastname: '',
      familiarSpousePEPmlastname: '',
      familiarSpousePEPnames: '',
      familiarSpousePEPbornDate: '',
      familiarSpousePEPtypeDocument: '',
      familiarSpousePEPnumberDocument: '',
      familiarSpousePEPisPEP: 'no',
      familiarSpousePEPcountry: '',
      familiarSpousePEPinstitution: '',
      familiarSpousePEPoccupation: '',
    }]
    this.shares = (userData.pepData && userData.pepData.shares) ? userData.pepData.shares : []
    this.isFamiliarPEP = (userData.pepData && userData.pepData.isFamiliarPEP) ? userData.pepData.isFamiliarPEP : 'no'
    this.isFamilyPEP = (userData.pepData && userData.pepData.isFamilyPEP) ? userData.pepData.isFamilyPEP : [{
      isFamiliarPEPname: '',
      isFamiliarPEPrelationship: '',
      isFamiliarPEPcountry: '',
      isFamiliarPEPbussinessName: '',
      isFamiliarPEPposition: '',
    }]
    this.affirmedAffidavit = (userData.pepData && userData.pepData.affirmedAffidavit) ? userData.pepData.affirmedAffidavit : 'no'
  },
  methods: {
    formatterDoc(value) {
      return this.partnertypeDocument === '0' ? this.formatterNumber(value) : this.formatterChars(value)
    },
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterChars(value) {
      return value.replace(/[^a-zA-Z0-9]/g, '')
    },
    formatterDecimal(value) {
      return value.replace(/[^0-9().]/g, '')
    },
    addedFamiliar() {
      this.familiarsPEP.push({
        familiarPEPcode: '',
        familiarPEPplastname: '',
        familiarPEPmlastname: '',
        familiarPEPnames: '',
        familiarPEPage: '',
      })
    },
    addFamiliarSpousePEP() {
      this.familySpousePEP.push({
        familiarSpousePEPplastname: '',
        familiarSpousePEPmlastname: '',
        familiarSpousePEPnames: '',
        familiarSpousePEPbornDate: '',
        familiarSpousePEPtypeDocument: '',
        familiarSpousePEPnumberDocument: '',
        familiarSpousePEPisPEP: 'no',
        familiarSpousePEPcountry: '',
        familiarSpousePEPinstitution: '',
        familiarSpousePEPoccupation: '',
      })
    },
    addShares() {
      this.shares.push({
        businessName: '',
        businessRuc: '',
        businessPercentage: '',
      })
    },
    addIsFamiliarPEP() {
      this.isFamilyPEP.push({
        isFamiliarPEPname: '',
        isFamiliarPEPrelationship: '',
        isFamiliarPEPcountry: '',
        isFamiliarPEPbussinessName: '',
        isFamiliarPEPposition: '',
      })
    },
    deleteFamiliar(index) {
      this.familiarsPEP.splice(index, 1)
    },
    removeFamiliarSpousePEP(index) {
      this.familySpousePEP.splice(index, 1)
    },
    removeShares(index) {
      this.shares.splice(index, 1)
    },
    removeIsFamiliarPEP(index) {
      this.isFamilyPEP.splice(index, 1)
    },
    formatterDocV2(td) {
      return td === '0' ? this.formatterNumber : this.formatterChars
    },
    validationForm() {
      if (this.userType === 1 && !this.canEdit) {
        this.$router.replace('/registro/datos-familiares')
      } else {
        this.$refs.pepDataForm.validate().then(success => {
          if (success) {
            const PEP = this
            const bodyPEP = {}

            bodyPEP.isPEP = PEP.isPEP
            if (PEP.isPEP === 'yes') {
              bodyPEP.informationPEP = {
                businessName: PEP.businessName,
                institution: PEP.institution,
                ruc: PEP.ruc,
                position: PEP.position,
                stillPosition: PEP.stillPosition,
                startDate: PEP.startDate,
                familiarsPEP: PEP.familiarsPEP,
                hasSpouse: PEP.hasSpouse,
                shares: PEP.shares,
              }
            }

            if (PEP.isPEP === 'yes' && PEP.stillPosition === 'no') {
              bodyPEP.informationPEP.endDate = PEP.endDate
            }

            if (PEP.hasSpouse === 'yes') {
              bodyPEP.informationPEP.familySpousePEP = PEP.familySpousePEP
            }

            bodyPEP.isFamiliarPEP = PEP.isFamiliarPEP
            if (PEP.isFamiliarPEP === 'yes') {
              bodyPEP.informationFamiliarPEP = PEP.isFamilyPEP
            }
            bodyPEP.affirmedAffidavit = PEP.affirmedAffidavit

            useJWT.pepData({
              ruc: this.userRuc,
              email: this.email,
              ...bodyPEP,
            })
              .then(({ data }) => {
                const user = data.user.value
                localStorage.setItem('userData', JSON.stringify(user))
                store.dispatch(
                  'elementVerticalMenu/setStatusStep',
                  { step: 'PEP-data', status: user.pepData.completed },
                  { root: true },
                )
                this.$router.replace('/registro/datos-familiares')
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Éxito',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Se ha guardado tu información con éxito.',
                        autoHideDelay: 5000,
                      },
                    })
                  })
              })
              .catch(error => {
                console.log('errores', error)
                this.$refs.pepDataForm.setErrors(error.response.data.error)
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

  .slide-y-enter-active,
  .slide-y-leave-active {
    transition: all 1s ease;
  }
  .slide-y-enter,
  .slide-y-leave-to {
    transform: translateY(20px);
    opacity: 0
  }
</style>
